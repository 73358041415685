<template>
  <ItemWrapper>
    <ValidationObserver v-slot="{ handleSubmit }">
      <v-form autocomplete="off" @submit.prevent="handleSubmit(submitForm)">
        <v-row>
          <v-col cols="12">
            <AppCard
              :title="$t('Edit Ticket Queue')"
              :prev-route="prevRoute"
              :loading="pending.getEditTimeReport || pending.getTimeReportPresets"
            >
              <v-container py-0>
                <v-row wrap>
                  <v-col cols="12" sm="6">
                    <VTextFieldWithValidation label="Client" disabled :value="client" />
                  </v-col>

                  <v-col cols="12" sm="6">
                    <VTextFieldWithValidation
                      label="Tenant"
                      :value="timeReportPresets.tenants && timeReportPresets.tenants[timeReport.tenant_id]"
                      disabled
                    />
                  </v-col>

                  <v-col cols="12" sm="6">
                    <VTextFieldWithValidation v-model="name" rules="required" label="Name" placeholder="Enter Name" />
                  </v-col>

                  <v-col cols="12" sm="6">
                    <VTextFieldWithValidation label="Period" disabled :value="period" />
                  </v-col>

                  <v-col cols="12" class="text-right">
                    <v-btn
                      type="submit"
                      class="mx-0 font-weight-light"
                      color="success"
                      :disabled="pending.editTimeReport"
                    >
                      Submit
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </AppCard>
          </v-col>

          <v-col v-if="Object.keys(clonedTimeReportTicketsWithTasks).length >= 1" cols="12">
            <AppCard :title="$t('Linked Tasks')">
              <div class="text-center d-flex pb-4">
                <v-btn small @click="openAllTimeReportPanels">Open all</v-btn>
                <v-btn small class="ml-2" @click="closeAllTimeReportPanels">Close all</v-btn>
              </div>
              <v-expansion-panels v-model="timeReportTicketPanels" multiple>
                <v-expansion-panel v-for="(ticket, key) in clonedTimeReportTicketsWithTasks" :key="ticket.id">
                  <v-expansion-panel-header>
                    <p class="h4 my-2">
                      {{ key === 'withOutTicket' ? 'Not assigned tasks' : `Ticket: ${ticket.subject}` }}
                      <v-chip class="ml-5" small color="primary">Tasks: {{ ticket.tasks.length }}</v-chip>
                      <v-chip v-if="getCountSelectedTicketTasks(ticket)" class="ml-5" small color="success">
                        Selected Tasks: {{ getCountSelectedTicketTasks(ticket) }}
                      </v-chip>
                    </p>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-data-table
                      v-model="time_tasks"
                      :headers="ticketTaskHeaders"
                      :items="ticket.tasks"
                      show-select
                      dense
                      item-key="id"
                      hide-default-footer
                    >
                      <template #[`item.from_date`]="{ item }">
                        <span>{{ $moment(item.from_date).format('DD.MM.YYYY HH:mm') }}</span>
                      </template>

                      <template #[`item.to_date`]="{ item }">
                        <span>{{ $moment(item.to_date).format('DD.MM.YYYY HH:mm') }}</span>
                      </template>

                      <template #[`item.user`]="{ item }">
                        <span v-if="item.user">
                          {{ item.user.firstname }}
                          {{ item.user.lastname }}
                        </span>
                      </template>
                    </v-data-table>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </AppCard>
          </v-col>

          <v-col v-if="Object.keys(ticketsWithTasks).length >= 1" cols="12">
            <AppCard :title="$t('Unlinked Tasks')">
              <div class="text-center d-flex pb-4">
                <v-btn small @click="openAllPanels">Open all</v-btn>
                <v-btn small class="ml-2" @click="closeAllPanels">Close all</v-btn>
              </div>
              <v-expansion-panels v-model="ticketPanels" multiple>
                <v-expansion-panel v-for="(ticket, key) in ticketsWithTasks" :key="ticket.id">
                  <v-expansion-panel-header>
                    <p class="h4 my-2">
                      {{ key === 'withOutTicket' ? 'Not assigned tasks' : `Ticket: ${ticket.subject}` }}
                      <v-chip class="ml-5" small color="primary">Tasks: {{ ticket.tasks.length }}</v-chip>
                      <v-chip v-if="getCountSelectedTicketTasks(ticket)" class="ml-5" small color="success">
                        Selected Tasks: {{ getCountSelectedTicketTasks(ticket) }}
                      </v-chip>
                    </p>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-data-table
                      v-model="time_tasks"
                      :headers="ticketTaskHeaders"
                      :items="ticket.tasks"
                      show-select
                      dense
                      item-key="id"
                      hide-default-footer
                    >
                      <template #[`item.from_date`]="{ item }">
                        <span>{{ $moment(item.from_date).format('DD.MM.YYYY HH:mm') }}</span>
                      </template>

                      <template #[`item.to_date`]="{ item }">
                        <span>{{ $moment(item.to_date).format('DD.MM.YYYY HH:mm') }}</span>
                      </template>

                      <template #[`item.user`]="{ item }">
                        <span v-if="item.user">
                          {{ item.user.firstname }}
                          {{ item.user.lastname }}
                        </span>
                      </template>
                    </v-data-table>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </AppCard>
          </v-col>
        </v-row>
      </v-form>
    </ValidationObserver>
  </ItemWrapper>
</template>

<script>
import { computed, defineComponent, onUnmounted, ref } from '@vue/composition-api'
import { mapFields } from 'vuex-composition-map-fields'

import useDirectRedirectToItem from '@/composables/useDirectRedirectToItem'

import AppCard from '@/components/UI/AppCard.vue'
import ItemWrapper from '@/layouts/ItemWrapper.vue'
import VTextFieldWithValidation from '@/components/inputs/VTextFieldWithValidation.vue'
import VSelectWithValidation from '@/components/inputs/VSelectWithValidation.vue'
import VAutocompleteWithValidation from '@/components/inputs/VAutocompleteWithValidation.vue'

export default defineComponent({
  name: 'EditTimeReport',
  components: {
    AppCard,
    ItemWrapper,
    VTextFieldWithValidation,
    VSelectWithValidation,
    VAutocompleteWithValidation,
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      // vm.prevRoute = from.fullPath === '/' ? vm.prevRoute : from.fullPath
    })
  },
  setup(props, { root: { $store, $router } }) {
    const { redirectDirect, setForceRedirect } = useDirectRedirectToItem()
    const prevRoute = ref('/TimeReports')
    const period = ref(null)

    const ticketTaskHeaders = [
      {
        sortable: false,
        text: 'Message',
        value: 'message',
        width: 250,
      },
      {
        sortable: false,
        text: 'From',
        value: 'from_date',
        width: 160,
      },
      {
        sortable: false,
        text: 'To',
        value: 'to_date',
        width: 160,
      },
      {
        sortable: false,
        text: 'User',
        value: 'user',
      },
    ]

    // store
    const pending = computed(_ => $store.state.timeReport.pending)
    const timeReportPresets = computed(_ => $store.state.timeReport.timeReportPresets)
    const timeReport = computed(_ => $store.state.timeReport.timeReport)
    const clonedTimeReportTicketsWithTasks = computed(
      _ => $store.getters['timeReport/clonedTimeReportTicketsWithTasks'],
    )
    const ticketsWithTasks = computed(_ => $store.getters['timeReport/ticketsWithTasks'])
    const errors = computed(_ => $store.state.timeReport.errors)
    const editTimeReport = _ => $store.dispatch('timeReport/editTimeReport')
    const getEditTimeReport = _ => $store.dispatch('timeReport/getEditTimeReport')
    const getUnlinkedTasks = clientId => $store.dispatch('timeReport/getUnlinkedTasks', clientId)
    const clearTimeReport = _ => $store.commit('timeReport/CLEAR_TIME_REPORT')

    getEditTimeReport().then(async _ => {
      period.value = `${timeReport.value.year}-${timeReport.value.month.toString().padStart(2, '0')}`
      await getUnlinkedTasks(timeReport.value.client_id)
      openAllPanels()
    })

    const client = computed(_ => {
      let client = timeReport.value?.client?.billing_handle.organisation
        ? `(${timeReport.value.client.billing_handle.organisation}) `
        : ''
      return (
        (client &&
          (client += `${timeReport.value.client.billing_handle.firstname} ${timeReport.value.client.billing_handle.lastname}`)) ||
        ''
      )
    })

    const timeReportMapFields = mapFields('timeReport', [
      'timeReport.name',
      'timeReport.time_tasks',
    ])

    const getCountSelectedTicketTasks = ticket => {
      return ticket.tasks.filter(task => timeReportMapFields.time_tasks.value.map(tt => tt.id).includes(task.id)).length
    }

    const submitForm = _ => {
      if (pending.value.editTimeReport) {
        return
      }
      if (redirectDirect.value) {
        setForceRedirect(true)
      }

      editTimeReport().then(({ timeReport, errors }) => {
        if (errors) {
          setForceRedirect(false)
          return
        }

        if (redirectDirect.value) {
          $router.push({
            name: 'timeReports-item',
            params: { id: timeReport.id },
          })
        } else {
          $router.push({ path: prevRoute.value })
        }
      })
    }

    const timeReportTicketPanels = ref([])
    const ticketPanels = ref([])

    const openAllTimeReportPanels = _ => {
      timeReportTicketPanels.value = Object.keys(ticketsWithTasks.value).map((k, i) => i)
    }

    const closeAllTimeReportPanels = _ => {
      timeReportTicketPanels.value = []
    }

    const openAllPanels = _ => {
      ticketPanels.value = Object.keys(ticketsWithTasks.value).map((k, i) => i)
    }

    const closeAllPanels = _ => {
      ticketPanels.value = []
    }

    onUnmounted(_ => {
      clearTimeReport()
    })

    return {
      prevRoute,
      pending,
      errors,
      timeReportPresets,
      timeReport,
      client,
      openAllPanels,
      closeAllPanels,
      openAllTimeReportPanels,
      closeAllTimeReportPanels,
      ticketTaskHeaders,
      timeReportTicketPanels,
      ticketPanels,
      ticketsWithTasks,
      clonedTimeReportTicketsWithTasks,
      getCountSelectedTicketTasks,
      period,
      submitForm,
      ...timeReportMapFields,
    }
  },
}) //
</script>
